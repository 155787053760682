import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLabelCard,
  CCenterSlider,
  CLabelList,
  CFixedImg,
  CustomLink,
  CCard,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: <>mon cheri</>,
            ja: '宴会場モンシェリー',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/mon_cheri/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/mon_cheri/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: 'モンシェリー',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              ホテルガーデンが見渡せる
              <br className="u_sp" />
              パーティルーム
            </h2>
            <p className="c_sectLead u_mbMedium">
              “最愛なる人たち”を意味する「モン
              シェリー」は、目の前に美しいガーデンが広がる南欧風ナチュラルテイストのパーティールームです。
              <br />
              テラスではウェルカムドリンクやデザートブッフェなど、様々なシチュエーションにお使いいただけます。
            </p>
            <CLabelList
              exClass="u_mbMedium"
              data={[
                'パーティー',
                '会議・コンベンション',
                '講演・セミナー',
                'イベント・展示',
              ]}
            />
            <CCenterSlider
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/mon_cheri/img_mon_cheri.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/mon_cheri/img_mon_cheri02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/mon_cheri/img_mon_cheri03.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: '基本情報',
                en: (
                  <>
                    BASIC <br className="u_sp" />
                    INFORMATION
                  </>
                ),
              }}
            />
            <p className="u_mb30">
              広さ 180㎡
              <br />
              収容人数 スクール60名～ブッフェ100名
            </p>
            {!isSp ? (
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th style={{ width: '180px' }} rowSpan={2}>
                      宴会場名
                    </th>
                    <th colSpan={7}>収容人数</th>
                  </tr>
                  <tr>
                    <th>ご利用範囲</th>
                    <th>広さ（m²）</th>
                    <th>坪</th>
                    <th>正餐</th>
                    <th>ビッフェ</th>
                    <th>スクール</th>
                    <th>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      ガーデンバンケット
                      <br />
                      「モン シェリー」
                    </th>
                    <td>全室</td>
                    <td>180</td>
                    <td>54</td>
                    <td>80</td>
                    <td>100</td>
                    <td>60</td>
                    <td>60</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>正餐</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          ガーデンバンケット
                          <br />
                          「モン シェリー」
                          <br />/ 全室
                        </td>
                        <td>180</td>
                        <td>54</td>
                        <td>80</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>ビッフェ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          ガーデンバンケット
                          <br />
                          「モン シェリー」
                          <br />/ 全室
                        </td>
                        <td>180</td>
                        <td>54</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb60">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>スクール</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          ガーデンバンケット
                          <br />
                          「モン シェリー」
                          <br />/ 全室
                        </td>
                        <td>180</td>
                        <td>54</td>
                        <td>60</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="c_fixedTableScroll u_mb30">
                  <table className="c_fixedTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>シアター</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '168px' }}>宴会場名/ご利用範囲</th>
                        <th style={{ width: '126px' }}>広さ（㎡）</th>
                        <th style={{ width: '126px' }}>坪</th>
                        <th style={{ width: '126px' }}>収容人数</th>
                      </tr>
                      <tr>
                        <td>
                          ガーデンバンケット
                          <br />
                          「モン シェリー」
                          <br />/ 全室
                        </td>
                        <td>180</td>
                        <td>54</td>
                        <td>60</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </>
            )}
            <p className="u_mbMedium">
              【宴会場付帯設備】
              <br />
              同時通訳設備・美容着付室・写真室・婦人着替室・紳士着替室・授乳室
            </p>
            <CFixedImg
              width={406}
              img={{
                src: '/assets/images/banquet/mon_cheri/img_mon_cheri04.png',
                alt: '',
              }}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'レイアウト例',
                en: (
                  <>
                    LAYOUT <br className="u_sp" />
                    EXAMPLE
                  </>
                ),
              }}
            />
            <CCard
              col={2}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/mon_cheri/img_mon_cheri05.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/mon_cheri/img_mon_cheri06.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'メニュー',
                en: <>MENU</>,
              }}
            />
            <CLabelCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_menu.png',
                    alt: '宴会・パーティー',
                  },
                  title: <>宴会・パーティー</>,
                  link: {
                    href: '/banquet/party/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu02.png',
                    alt: '会議・講演・セミナー',
                  },
                  title: <>会議・講演・セミナー</>,
                  link: {
                    href: '/banquet/meeting/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu03.png',
                    alt: 'イベント・展示',
                  },
                  title: <>イベント・展示</>,
                  link: {
                    href: '/banquet/event/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu04.png',
                    alt: 'インセンティブ',
                  },
                  title: <>インセンティブ</>,
                  link: {
                    href: '/banquet/incentive/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu05.png',
                    alt: 'ケータリング',
                  },
                  title: <>ケータリング</>,
                  link: {
                    href: '/banquet/catering/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: '宴会場',
                en: <>BANQUET</>,
              }}
            />
            <CLabelCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet.png',
                    alt: 'ロイヤルホール',
                  },
                  label: '大宴会場',
                  title: <>ロイヤルホール</>,
                  text: <>ロビーフロア</>,
                  link: {
                    href: '/banquet/grand_hall/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet02.png',
                    alt: 'クラウンルーム',
                  },
                  label: '中宴会場',
                  title: <>クラウンルーム</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/crown/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet03.png',
                    alt: 'モンシェリー',
                  },
                  label: '中宴会場',
                  title: <>モンシェリー</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/mon_cheri/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet04.png',
                    alt: 'エクスクルーシブテーブル',
                  },
                  label: '小宴会場',
                  title: <>エクスクルーシブテーブル</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/exclusive_table/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet05.png',
                    alt: 'うたげ',
                  },
                  label: '小宴会場',
                  title: <>うたげ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet06.png',
                    alt: 'かすみ',
                  },
                  label: '小宴会場',
                  title: <>かすみ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#kasumi',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet07.png',
                    alt: 'あや',
                  },
                  label: '小宴会場',
                  title: <>あや</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#aya',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
